import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentArea from "../components/contentArea"
import PersonDetail from "../components/personDetail"
import Heading from "../components/heading"
import content from "../../content/associates.json"
import { Paragraph } from "../types/paragraph"

const ReferralPage = () => (
    <Layout pageName="referrals">
        <ContentArea>
            <Heading {...{ level: 1, content: "Referral Process" }} />
            { content.profiles.map((profile) => {
                return <PersonDetail
                    name={profile.name}
                    titles={profile.titles}
                    content={profile.referralProcess as Array<Paragraph>}
                    key={profile.name} />
            })}
        </ContentArea>
    </Layout>
)

export const Head = () => <Seo title="Referrals" />

export default ReferralPage
