import React, { FunctionComponent, useState } from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import { createUseStyles } from 'react-jss'
import { Paragraph } from '../types/paragraph'
import ParagraphBlock from './paragraph'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',    
        padding: '30px 0 15px 0',
        borderTop: '1px solid #edf3fd'
    },
    personCard: {
        width: '25%',
        textAlign: 'center'
    },
    cardTitleName: {
        fontSize: '18px',
        marginBottom: '0.5rem',
        lineHeight: 1.2,
        fontFamily: "Playfair Display, serif",
        fontWeight: 'bold'
    },
    cardTitleDescription: {
        marginBottom: '1rem',
        marginTop: 0
    },
    aboutContainer: {
        width: '75%'
    },
    paragraph: {
        padding: '0 5%',
        textAlign: 'left',
        marginTop: 0
    }
})

export interface PersonDetailProps {
    name: string,
    titles: Array<string>,
    content: Array<Paragraph>
}

const PersonDetail: FunctionComponent<PersonDetailProps> = (props) => {
    const styles = useStyles()
    const nonCharacterRegex = /[^a-zA-Z]+/g;
    const id = props.name.replace(nonCharacterRegex, '-').toLowerCase();

    return (
        <>
            <div className={styles.container} id={id}>
                <div className={styles.personCard}>
                    <div className={styles.cardTitleName}>{props.name}</div>
                    <p className={styles.cardTitleDescription}>{props.titles.join(", ")}</p>
                </div>
                <div className={styles.aboutContainer}>
                    {props.content.map((paragraph, index) => {
                        return <ParagraphBlock {...paragraph} key={`paragraph-${index}`} className={styles.paragraph}/>
                    })}
                </div>
            </div>
        </>
    )
}

export default PersonDetail